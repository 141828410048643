<script setup>
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon } from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/20/solid";

const notificationStore = useNotificationStore();

// ==================== CONSTANTS ====================

const tickTimeout = ref(setTimeout(() => {}, 0));
const progress = ref(100);

// ==================== VARIABLES ====================

const notificationToRender = computed(() => {
  return notificationStore.GET_ALL.slice(0, 3);
});

// ==================== FUNCTIONS ====================

const tick = (/** @type {number} */ index) => {
  if (index !== 0) return;
  clearTimeout(tickTimeout.value);
  tickTimeout.value = setTimeout(() => notificationStore.REMOVE(index), 5000);
  progress.value = tickTimeout.value / 10;
};
</script>

<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 top-20 z-50 flex items-start px-4 py-6 sm:top-28 sm:p-6"
  >
    <div class="flex w-full flex-col items-center sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <TransitionGroup
        enter-active-class="transform ease-in-out duration-300 transition-all"
        enter-from-class="opacity-0 scale-50"
        enter-to-class="opacity-100"
        leave-active-class="transform transition-all ease-out duration-500"
        leave-from-class="translate-x-0 opacity-100"
        leave-to-class="translate-x-1/2 opacity-0"
      >
        <div
          v-for="(notification, index) in notificationToRender"
          :key="notification.id"
          class="pointer-events-auto absolute w-full max-w-xs -translate-y-3/4 overflow-hidden rounded-lg bg-white shadow-around transition-all duration-300 ease-in-out dark:bg-[#0F2775] dark:shadow-[#00bbba] sm:max-w-sm"
          :class="{
            'z-10 translate-y-[25%] scale-75 blur-[2px]': index === 2,
            'z-20 translate-y-[50%] scale-90 blur-[2px]': index === 1,
            'z-30 scale-100 opacity-100': index === 0,
          }"
          @transitionstart="tick(index)"
        >
          <div class="p-4">
            <div class="flex items-center">
              <div class="shrink-0">
                <CheckCircleIcon
                  v-if="notification.type === 'success'"
                  class="size-6 text-green-400"
                  aria-hidden="true"
                />
                <ExclamationCircleIcon
                  v-else-if="notification.type === 'error'"
                  class="size-6 text-red-400"
                  aria-hidden="true"
                />
                <InformationCircleIcon v-else class="size-6 text-blue-400" aria-hidden="true" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="whitespace-break-spaces break-words text-base font-medium text-gray-900 dark:text-white">
                  {{ notification.title }}
                </p>
                <p class="dark: mt-1 whitespace-break-spaces break-words text-base text-gray-500 dark:text-white">
                  {{ notification.message }}
                </p>
              </div>
              <div class="ml-4 flex shrink-0">
                <AtomButton
                  aria-label="Close Notification"
                  type="button"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none dark:bg-[#0F2775]"
                  @click="notificationStore.REMOVE(index)"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="-m-1 size-6 p-1 text-black/50 dark:text-white" aria-hidden="true" />
                </AtomButton>
              </div>
            </div>
          </div>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>
